function authenticate_payment(client_secret, obj) {
  stripe.confirmCardPayment(client_secret).then(function(result) {
    console.log("authenticate payment result", result)
    if (result.error) {
      toastr.error(
        result.error.message,
        "Authentication Error",
        { timeOut: 0 }
      );
    } else {
      toastr.success(I18n.t("payment_method.create.success_past_due"));
    }
  });
}

function mountStripeElements() {

  if( !$("#card_number").length ) {
    return;
  }

  /***** Transform card into Stripe object */
  var style = {
    base: {
      fontFamily: 'Roboto, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
      fontSize: '15px'
    }
  }

  var elements = stripe.elements();
  var cardNumber = elements.create('cardNumber', { style: style });
  var cardExpiry = elements.create('cardExpiry', { style: style });
  var cardCvc = elements.create('cardCvc', { style: style });

  cardNumber.mount("#card_number")
  cardExpiry.mount("#card_expiry")
  cardCvc.mount("#card_cvc")

  /* Credit Card identification */
  cardNumber.on("change", (ev) => {
    $('.brand').hide();
    $('.' + ev.brand).show();
  });

  $(document).on('ajax:beforeSend', '#payment-form', () => { return false; });

  var form = document.getElementById('payment-form');

  form.addEventListener('submit', function(ev) {
    ev.preventDefault();

    var submit = document.getElementById('stripe-submit');
    Rails.disableElement(submit);

    stripe.createPaymentMethod({
      type: 'card',
      card: cardNumber
    }).then(stripePaymentMethodHandler);
  });

  function stripePaymentMethodHandler(result) {
    var submit = document.getElementById('stripe-submit');

    if (result.error) {
      toastr.error(
        result.error.message,
        "Payment Error",
        { timeOut: 0 }
       );
      Rails.enableElement(submit);
    } else {
      $.post(
        `/${I18n.locale}/payment_method/create`,
        {
          user_id: $("#user_id").val(),
          pm_id: result.paymentMethod.id
        },
        (result) => {
          if (typeof result.payment_intent !== 'undefined') {
            const { client_secret, status } = result.payment_intent;

            if (status === 'requires_action') {
              stripe.confirmCardPayment(client_secret).then(function(result) {
                console.log(result);
                if (result.error) {
                  toastr.error(
                    result.error.message,
                    "Authentication Error",
                    { timeOut: 0 }
                  );
                  Rails.enableElement(submit);
                }
              });
            }
          } else {
            Rails.enableElement(submit);
          }
        }
      );
    }
  }
}

$(document).on("turbo:load", () => {
  if( $("body.users-edit").length ) {
    mountStripeElements();
    console.log('mountStripeElements')

    $(document).on('click', '.authenticate-payment', (e) => {
      if(e.target.href.endsWith("#cards")) {
        return true;
      } else {
        e.target.href = "#cards"
      }
      
      var client_secret = $(e.target).data('client-secret');
      
      authenticate_payment(client_secret, e.target);
      
      e.preventDefault();
    });
  }
});

$(document).on('stripe:reload', function(evt, data, status, xhr) {
  if( $("body.users-edit").length ) {
    console.log("card-form.js");
    mountStripeElements();
  }
});
