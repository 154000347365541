function autoResize() { 
    this.style.height = 'auto'; 
    this.style.height = this.scrollHeight + 'px'; 
}

$(document).on("turbo:load", () => {
  if($(".auto-resize")[0]) {
    $(".auto-resize").each(function() {
      $(this)[0].addEventListener('input', autoResize, false); 
    });
  }
});