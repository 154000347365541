function callbackOnFileUploadFinished(data) {
  url = data.filesUploaded[0].url

  $.post(`/${I18n.locale}/user/update_avatar`, { avatar: url }, (result) => {});
}

function setup_users() {
  $("#avatar").on("click", () => {
    var options = {
      transformations: {
        circle: true,
        force: true
      },
      onUploadDone: data => callbackOnFileUploadFinished(data)
    };
    filestack_client.picker(options).open()
  });

  if($('.datepicker').length) {
    $('.datepicker').datepicker({
      startView: "years",
      autoclose: true,
      endDate: new Date(),
      format: {
        toDisplay: function (date, format, language) {
          $('.datepicker-target').val(moment.utc(date).format("YYYY-MM-DD"));
          return moment.utc(date).format("MM/DD/YYYY");
        },
        toValue: function () {}
      }
    });
  }

  // Javascript to enable link to tab
  var url = document.location.toString();
  if (url.match('#')) {
    var tabName = url.split('#')[1];
    tabName = tabName.split('?')[0];
    var el = document.getElementById(tabName+"-tab");

    $(".nav-link.active").removeClass("active");
    $(".tab-pane.active").removeClass("show");
    $(".tab-pane.active").removeClass("active");
    
    $(el).addClass('active');
    
    $(".tab-pane#"+tabName).addClass("show");
    $(".tab-pane#"+tabName).addClass("active");

    $.get(el.getAttribute("href"), function(data, status) {
      // console.log(data);
      // console.log(status);
      var theInstructions = data;

      // executing the JS return from the endpoint
      var F=new Function (theInstructions);
      F();
    });
  } 

  $('.nav-pills a').on('shown.bs.tab', function (e) {
    var newHash = $(this).data('target').replace('#', '');
    console.log('newHash', newHash);
    if (window.location.hash != newHash) {
      window.location.hash = newHash;
    }
  });
}

$(document).on("turbo:load", () => {
  if( $("body.users-edit").length ) {
    console.log("users.js");
    setup_users();
  }
});