var timeout;
function countdown(time_in_seconds) {
  if (time_in_seconds <= 0) {
    $(".countdown").hide();
    return false;
  }

  var seconds = time_in_seconds % 60;
  var minutes = (time_in_seconds / 60) % 60;
  var hours = (time_in_seconds / 3600) % 24;
  var days = time_in_seconds / (3600 * 24);

  $(".countdown .seconds").html( ("0" + Math.trunc(seconds)).slice(-2) );
  $(".countdown .minutes").html( ("0" + Math.trunc(minutes)).slice(-2) );
  $(".countdown .hours").html( ("0" + Math.trunc(hours)).slice(-2) );
  $(".countdown .days").html( ("0" + Math.trunc(days)).slice(-2) );

  timeout = setTimeout(() => {
    countdown(time_in_seconds - 1);
  }, 1000);
}

$(document).on("turbo:before-cache", () => {
  if(timeout) {
    clearTimeout(timeout);
  }
});

$(document).on("turbo:load", () => {
  if( $("body.site-landingpage").length ||
      $("body.home-index").length ||
      $("body.payment-new").length ||
      $("body.posts-show").length ||
      $("body.lessons-show").length ) {
    if( $(".countdown").length ) {
      var time_in_seconds = $(".countdown").data("seconds");
      console.log("time_in_seconds", time_in_seconds);
      console.log("hello");
      countdown(time_in_seconds);
    }
  }
});