// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require('../source/vendor/assets/libs/toastr/toastr')
// import '../source/vendor/assets/libs/toastr/toastr'

// import "./controllers"
import "@hotwired/turbo-rails"

// import Rails from "@rails/ujs"
// Rails.starts()

import mrujs from "mrujs";
import { JsErb } from "mrujs/plugins"

mrujs.start({
  plugins: [
    JsErb()
  ]
})

console.log('frontend/entrypoints')

// require("@rails/activestorage").start()
import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()
// require("../channels")

// import "../jquery"

import I18n from 'i18n-js'
window.I18n = I18n


import '../source/vendor/assets/javascripts/bootstrap'

// window.moment = require('moment/moment')
import moment from 'moment/moment';
window.moment = moment;

import '../source/vendor/assets/libs/bootstrap-datepicker/bootstrap-datepicker'
import '../source/vendor/assets/libs/bootstrap-datepicker/bootstrap-datepicker.scss'

import '../source/vendor/assets/fonts/fontawesome.css'
import '../source/vendor/assets/stylesheets/bootstrap.scss'
import '../source/vendor/assets/stylesheets/appwork.scss'
import '../source/vendor/assets/stylesheets/theme-paper.scss'
import '../source/vendor/assets/stylesheets/colors.scss'
import '../source/vendor/assets/stylesheets/uikit.scss'

// Include main stylesheet
import "../source/comment.scss"
import "../source/faq.scss"
import "../source/home.scss"
import "../source/main.scss"
import "../source/payment.scss"
import "../source/session.scss"
import "../source/site.scss"
import "../source/style.scss"
import "../source/users.scss"
import "../source/video.scss"
import "../source/actiontext.scss"
import '../source/navigation-footer.scss'
import '../source/post.scss'

// Include main file
// require("../source/card-form")
// require("../source/comment")
// require("../source/countdown")
// require("../source/main")
// require("../source/notification")
// require("../source/payment")
// require("../source/site")
// require("../source/users")
// require("../source/searchable-select")
// require("../source/home")
// require("../source/lazy-loaded-iframes")
// require("../source/highlighted-comment")
// require("../source/video")
// require("../source/email-confirmation")
// require("../source/external-link-to-new-tab")
// require("../source/zoom")
import "../source/card-form"
import "../source/comment"
import "../source/countdown"
import "../source/main"
import "../source/notification"
import "../source/payment"
import "../source/site"
import "../source/users"
import "../source/searchable-select"
import "../source/home"
import "../source/lazy-loaded-iframes"
import "../source/highlighted-comment"
import "../source/video"
import "../source/email-confirmation"
import "../source/external-link-to-new-tab"


// require("chartkick")
// require("chart.js")

// Initialize turbolinks
// require("turbolinks").start()

// select2
// import $ from 'jquery';
// require("select2")
// require("select2/dist/css/select2.css")

import '../source/owl.carousel.min.css'
import '../source/owl.theme.default.min.css'
// require('../source/owl.carousel.min')
import '../source/owl.carousel.min'
console.log("CAROUSEL")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// require("trix")
// require("@rails/actiontext")
import "trix"
import "@rails/actiontext"

// console.log('toastr', toastr)
import toastr from 'toastr';
window.toastr = toastr

import '../source/toastr.scss'


if("Notification" in window) {
  try {
    Notification.requestPermission()
      .then(function(result) { console.log(result); });
  } catch (error) {
    // Safari doesn't return a promise for requestPermissions and it
    // throws a TypeError. It takes a callback as the first argument
    // instead.
    if (error instanceof TypeError) {
      Notification.requestPermission(function(result) {
        console.log(result);
      });
    } else {
      throw error;
    }
  }
}

if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/serviceworker.js')
  .then(function(reg) {
    console.log('Service worker change, registered the service worker');
    navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
      serviceWorkerRegistration.pushManager
      .subscribe({
        userVisibleOnly: true,
        applicationServerKey: window.vapidPublicKey
      });
    });
  });
}
// Otherwise, no push notifications :(
else {
  console.error('Service worker is not supported in this browser');
}

$(document).on('turbo:load', function(){
  I18n.locale = $('body').data('locale')
  console.log('I18n.locale >>>', I18n.locale)
});
