var interval = null;

$(document).on("turbo:load", () => {
    countdown(120);

    $('.email-confirmation-input').keypress(function (e) {    
        var charCode = (e.which) ? e.which : event.keyCode;

        if ($(this).val().length >= 6) {
            return false;
        }

        if (String.fromCharCode(charCode).match(/[^0-9]/g)) {
            return false;
        }
    });

    $('.generate-new').click(function() {
        countdown(120);
    });
});

function countdown(count) {
    $('.generate-new-container').hide();
    $('.wait-container').show();
    
    if (!interval) {
        interval = setInterval(function() {
            if(count == 0) {
                $('.generate-new-container').show();
                $('.wait-container').hide();
                clearInterval(interval);
                interval = null;
            }

            $(".wait-container .counter").html(count);
            count -= 1;
        }, 1000);
    }
}