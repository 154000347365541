function setup_payment() {
  console.log('I18n.locale <<<<', I18n.locale)
  fbq('track', 'InitiateCheckout');

  /***** Transform card into Stripe object */
  var style = {
    base: {
      fontFamily: 'Roboto, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
      fontSize: '15px'
    }
  }

  var elements = stripe.elements();
  var cardNumber = elements.create('cardNumber', { style: style });
  var cardExpiry = elements.create('cardExpiry', { style: style });
  var cardCvc = elements.create('cardCvc', { style: style });

  cardNumber.mount("#card_number")
  cardExpiry.mount("#card_expiry")
  cardCvc.mount("#card_cvc")

  /* Credit Card identification */
  cardNumber.on("change", (ev) => {
    $('.brand').hide();
    $('.' + ev.brand).show();
  });

  $(document).on('ajax:beforeSend', '#payment-form', () => { return false; });

  $('.select-plan-stripe').click((e) => {
    $('#plan_id').val($(e.target).data('plan'));
  });

  $('.select-plan-paypal').click((e) => {
    $('#paypal-buttons').html("");
    
    paypal.Buttons({
      createSubscription: function(data, actions) {
        // This function sets up the details of the transaction, including the amount and line item details.
        return actions.subscription.create({
          'plan_id': $(e.target).data('plan'),
          'application_context' : { 
            'shipping_preference': 'NO_SHIPPING'
          }
        });
      },
      onApprove: function(data, actions) {
        console.log(data);

        $.post(
          `/${I18n.locale}/payment/paypal/create`,
          {
            lead_user_token: $("#lead_user_token").val(),
            paypal_id: data.subscriptionID
          },
          (result) => {
            // nothing to do here ?
          }
        );
      }
    }).render('#paypal-buttons');
  });

  var form = document.getElementById('payment-form');

  form.addEventListener('submit', function(ev) {
    ev.preventDefault();

    var submit = document.getElementById('stripe-submit');
    Rails.disableElement(submit);

    stripe.createPaymentMethod({
      type: 'card',
      card: cardNumber
    }).then(stripePaymentMethodHandler);
  });

  function stripePaymentMethodHandler(result) {
    var submit = document.getElementById('stripe-submit');
    console.log('stripePaymentMethodHandler', result)

    if (result.error) {
      toastr.error(
        result.error.message,
        "Payment Error",
        { timeOut: 0 }
       );
      Rails.enableElement(submit);
    } else {
      $.post(
        `/${I18n.locale}/payment/create`,
        {
          lead_user_token: $("#lead_user_token").val(),
          plan_id: $("#plan_id").val(),
          coupon_code: $("#coupon_code").val(),
          email: $("#email").val(),
          campaign: $("#campaign").val(),
          pm_id: result.paymentMethod.id,
          ab_test: $("#ab_test_name").val()
        },
        (result) => {
          console.log(result);
          if (result.status === 'ok') {
            gtag_report_conversion(result.value, result.currency, result.invoice_id);
            fbq('track', 'Purchase', {currency: result.currency, value: result.value });
            window.location = result.redirect;
          } else if (typeof result.payment_intent !== 'undefined') {
            const { client_secret, status } = result.payment_intent;

            if (status === 'requires_action') {
              stripe.confirmCardPayment(client_secret).then(function(result) {
                if (result.error) {
                  toastr.error(
                    result.error.message,
                    "Authentication Error",
                    { timeOut: 0 }
                  );
                  Rails.enableElement(submit);
                } else {
                  $.post(
                    `/${I18n.locale}/payment/finish`,
                    {
                      lead_user_token: $("#lead_user_token").val(),
                      plan_id: $("#plan_id").val()
                    },
                    (result) => {
                      // google report conversion
                      gtag_report_conversion(result.payment_intent.amount / 100, result.payment_intent.currency, payment_intent.id);
                      // facebook pixel
                      fbq('track', 'Purchase', {currency: result.payment_intent.currency, value: result.payment_intent.amount / 100 });
                      Rails.enableElement(submit);
                    }
                  );
                }
              });
            } else {
              toastr.error(
                "We are sorry, there was an error processing your payment. Please try again with a different card.",
                "Payment Error",
                { timeOut: 0 }
              );
              Rails.enableElement(submit);
            }
          } else if(result.pending_setup_intent) {
            // setup intent is used to check the card in the case of trial
            // this will ask for 3D auth if any is needed even if the first payment is 0 (in the case of a trial period)
            const {client_secret, status} = result.pending_setup_intent;

            if (status === "requires_action") {
              stripe.confirmCardSetup(client_secret).then(function(result) {
                if (result.error) {
                  toastr.error(
                    result.error.message,
                    "Authentication Error",
                    { timeOut: 0 }
                  );
                  Rails.enableElement(submit);
                } else {
                  $.post(
                    `/${I18n.locale}/payment/finish`,
                    {
                      lead_user_token: $("#lead_user_token").val(),
                      plan_id: $("#plan_id").val()
                    },
                    (result) => {
                      // google report conversion
                      gtag_report_conversion(0, (I18n.locale == "pt" ? "BRL" : "USD"), client_secret);
                      // facebook pixel
                      fbq('track', 'Purchase', {currency: (I18n.locale == "pt" ? "BRL" : "USD"), value: 0 });
                      Rails.enableElement(submit);
                    }
                  );
                }
              });
            } else if (status === "requires_payment_method") {
              toastr.error(
                "We are sorry, there was an error processing your payment. Please try again with a different card.",
                "Payment Error",
                { timeOut: 0 }
              );
              Rails.enableElement(submit);
            }
          } else if(result.redirect) {
            console.log(result.redirect);
            // Turbo.visit(result.redirect);
            if(result.message) {
              console.log(result.message);
              toastr.error(result.message, "Error", { timeOut: 0 });
            }
          } else if(result.message) {
            toastr.error(result.message, "Error", { timeOut: 0 });
            Rails.enableElement(submit);
          } else {
            Rails.enableElement(submit);
          }
        }
      );
    }
  }
}

$(document).on("turbo:load", () => {
  if( $("form#payment-form").length ) {
    I18n.locale = $('body').data('locale')
    setup_payment();
    
  }
});