function setup_site() {

  setCookie = (cname, cvalue, exdays) => {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  getCookie = (cname) => {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  if($("#dismiss-live-notification").length > 0){
    if(getCookie('live-notification') != ""){
      $("#live-notification").hide();
    }else{
      $("#live-notification").show();
    }
  }

  $("#dismiss-live-notification").click(() => {
    console.log("asass");  
    setCookie('live-notification', 'true', 7)
  })

  var iframe = $("#modalVideo");
  var player = new Vimeo.Player(iframe);

  $("#videoPlayerModal").on("hide.bs.modal",
    () => {
      player.pause();
    }
  );
  
  

  

  //Owl Carousel
  $('#three-post').owlCarousel({
    loop:true,
    nav: false,
    dots: true,
    autoplay:true,
    autoplayTimeout:3000,
    autoplayHoverPause:true,
    responsive:{
      0:{
        items:1
      },
      600:{
        items:2
      },
      1000:{
        items:3
      }
    }
  });

  $('#two-post').owlCarousel({
    loop:true,
    nav: false,
    dots: true,
    autoplay:true,
    autoplayTimeout:3000,
    autoplayHoverPause:true,
    responsive:{
      0:{
        items:1
      },
      600:{
        items:1
      },
      1000:{
        items:2
      }
    }
  });

  $('#single').owlCarousel({
    loop:true,
    nav: false,
    dots: true,
    autoplay:true,
    autoplayTimeout:3000,
    autoplayHoverPause:true,
    // animateOut: 'fadeOut',
    items: 1,
  })
}

$(document).on("turbo:load", () => {
  if( $("body.site-landingpage").length ) {
    console.log("site.js");
    setup_site();
  }
});