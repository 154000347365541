import { video_watch_path } from '../routes';

$(document).on("turbo:load", () => {
  $(".lazyload").each(function() {
    lazyload($(this));
  });

  $(".bg-lazyload").each(function() {
    lazyload($(this), true);
  });
});

$(document).on('scroll', () => {
  $(".lazyload").each(function() {
    lazyload($(this));
  });

  $(".bg-lazyload").each(function() {
    lazyload($(this), true);
  });
});

function isElementVisible(element, offset=500) {
    var top_of_element = element.offset().top - offset;
    var bottom_of_element = top_of_element + element.outerHeight() + offset;
    var top_of_screen = $(window).scrollTop();
    var bottom_of_screen = top_of_screen + $(window).innerHeight();

    return (bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element);
}

function lazyload(element, as_bg_image=false) {
  if( isElementVisible(element) ) {
    var src = element.data('src');

    if(src) {
      if(as_bg_image)  element.css('background-image', 'url("' + src + '")');
      else             element.attr('src', src);
      
      element.data('src', null);
    }

    if( element.prop('nodeName') == "IFRAME" ) {
      var player = new Vimeo.Player(element);

      player.on('timeupdate', function(data) {
        if( data.percent >= 0.75 && element.data('should-watch') == true ) {
          markVideoAsWatched(element);
        }
      })
    }
  }
}

function markVideoAsWatched(element) {
  var video_slug = element.data('video');
  var url = video_watch_path({ locale: I18n.locale, slug: video_slug });

  var csrf_token = $('meta[name="csrf-token"]').attr("content");
  
  $.post({ url: url, headers: { "X-CSRF-Token": csrf_token } }, function (data) {
    toastr.success(I18n.t('video.show.watched'));
  }).fail(function(data) {
    element.data('should-watch', true);
  });

  element.data('should-watch', false);
}