$(document).on("turbo:load", () => {
  if($("select.searchable").length) {
    $("select.searchable").select2();
  }

  if($("select.searchable-multiple").length) {
    $("select.searchable-multiple").select2({
      allowClear: true,
      placeholder: 'Insert hashtags (optional but preferable)',
      multiple: true,
      tags: true,
      tokenSeparators: [',', ' '],
      initSelection: function(element, callback) {
      }
    });
  }
});