function set_blank() {
  $('a').each(function(idx, link) {
    if (link.host !== window.location.host) {
      link.target = "_blank";
    }
  })
}

$(document).on("turbo:load", () => {
  set_blank();
});

$( document ).ajaxComplete(function() {
  set_blank();
});