var loadingPosts = { all: false, except_lesson_posts: false, lesson_posts: false };
$(window).scroll(function () {
    // End of the document reached?
    var offset = 2000;
    var postType = $(".post-filter-nav-link.active").data("type")
    if (!loadingPosts[postType] && $(document).height() - $(this).height() <= $(this).scrollTop() + offset) {
      var page = $("#post-list-"+postType).data("page");
      var more = $("#post-list-"+postType).data("more");
      var hashtag = $("#post-list-"+postType).data("hashtag");
      var type = $("#post-list-"+postType).data("type");
      var link = "posts/list/"+type+"?p="+(parseInt(page)+1);

      if (hashtag) {
       link += "&h="+hashtag;
      }

      if (!more) return;

      loadingPosts[postType] = true;
      $(".loading-posts").show();

      $.get(link,
        function() {
          loadingPosts[postType] = false;
          $(".loading-posts").hide();
        }
      ).fail(function() {
          // we don't set loadingPosts to false
          // so it will stop trying to load posts again
          $(".loading-posts").hide();
        }
      );
    }
});

$(document).on("turbo:load", () => {
  if ($(".signup-modal.not-seen").length) {
    $(".signup-modal").modal({
      backdrop: "static",
      show: true
    });
  }

  if ($(".trial-notification-modal").length) {
    $(".trial-notification-modal").modal({
      show: true
    });
  }

  if($('.course-carousel').length) {
    $(".course-carousel").owlCarousel({
      margin: 10,
      nav: true,
      navContainer: '.course-carousel-nav',
      navText: ['<div id="course-carousel-nav-prev"><</div>','<div id="course-carousel-nav-next">></div>'],
      responsiveClass: true,
      lazyContent: true,
      responsive:{
        0: {
          items: 2
        },
        601: {
          items: 3
        },
        1024: {
          items: 4
        }
      }
    });
  }

  if($('.top-banner-carousel').length) {
    $(".top-banner-carousel").owlCarousel({
      loop: true,
      nav: false,
      center: true,
      margin: 15,
      autoplay: true,
      autoplayTimeout: 5000,
      autoplayHoverPause: true,
      stagePadding: 100,
      responsive:{
        0: {
          items: 1,
          stagePadding: 0,
          margin: 0
        },
        601: {
          items: 1,
          stagePadding: 50
        },
        1501: {
          items: 2
        }
      }
    });
  }

  $( "#teste" ).on( "click", function() {
    console.log("clicou")
  });
});