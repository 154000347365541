function setup() { 
  
  $.post(
    `/${I18n.locale}/notifications/read`,
    (result) => {
      // do nothing
    }
  );
  
}

$(document).on("turbo:load", () => {
  if( $("body.notifications-index").length ) {
    setup();
  }
});