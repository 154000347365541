(function(win, doc, $) {
  const { themeSettings, layoutHelpers, attachMaterialRipple, detachMaterialRipple, SideNav } = win
  let [ firstLoad, sidenavInstance ] = [ true, null ]

  doc.addEventListener('turbo:before-render', e => {
    if (!themeSettings || $('html')[0].hasAttribute('data-turbolinks-preview')) return

    // Remove unwanted merged stylesheets on each render
    $('.theme-settings-bootstrap-css').slice(1).remove()
    $('.theme-settings-appwork-css').slice(1).remove()
    $('.theme-settings-theme-css').slice(1).remove()
    $('.theme-settings-colors-css').slice(1).remove()

    // Setup theme settings element
    if (!firstLoad) {
      $('.layout-wrapper', e.data.newBody).addClass('layout-sidenav-link-no-transition')
      themeSettings.updateNavbarBg(e.data.newBody)
      themeSettings.updateSidenavBg(e.data.newBody)
      themeSettings.updateFooterBg(e.data.newBody)
      themeSettings._setup(e.data.newBody)
      setTimeout(() =>
        $('.layout-wrapper', e.data.newBody).removeClass('layout-sidenav-link-no-transition')
      , 50)
    }
  })

  doc.addEventListener('turbo:load', () => {

    if (layoutHelpers) {
      layoutHelpers.init()

      // Update layout
      layoutHelpers.update()

      // Auto update layout
      layoutHelpers.setAutoUpdate(true)

      // Hide sidenav on small screens after page load
      if (layoutHelpers.isSmallScreen()) {
        layoutHelpers.setCollapsed(true, true)
      }
    }

    $(document).on('click', function(event) {
      var $target = $(event.target);
      if($('.search-container.collapse.show').is(":visible") && !$target.closest('.form-control').length) {
        $('.search-container.collapse.show').toggleClass("show")
      }        
    });

    $("#searchContainer").on('shown.bs.collapse', function() {
      $('#query').trigger("focus");
    })

    // Attach material ripple
    if (!firstLoad && attachMaterialRipple && $('html').hasClass('material-style')) {
      attachMaterialRipple()
    }

    // Initialize sidenav
    $('#layout-sidenav').each(function () {
      sidenavInstance = new SideNav(this, {
        orientation: $(this).hasClass('sidenav-horizontal') ? 'horizontal' : 'vertical'
      })
    })

    // Initialize sidenav togglers
    $('body').on('click', '.layout-sidenav-toggle', e => {
      e.preventDefault()
      layoutHelpers.toggleCollapsed()
    })

    // Swap dropdown menus in RTL mode
    if ($('html').attr('dir') === 'rtl') {
      $('#layout-navbar .dropdown-menu').toggleClass('dropdown-menu-right')
    }

    $(".navbar-toggler").on("click", function () {
      console.log('open mobile menu');
      $( "#nav-mobile-container" ).animate({
        right: 0,
      }, 500, function() {
        // Animation complete.
      });
    });
  
    $("#close-nav-mobile").on("click", function () {
      $( "#nav-mobile-container" ).animate({
        right: "-300px",
      }, 500, function() {
        // Animation complete.
      });
    });
  })

  // Home Courses Carousel
  // if (document.getElementById('course-carousel-nav-next')) {
  //   var anchors = document.getElementById('course-carousel-nav-next');
  //   var anchors2 = document.getElementById('course-carousel-nav-prev');
  //   let carouselPosition = 1;
  //   let maxCarouselPositions = 2;
  //   let countPositions = document.querySelectorAll('.course-carousel-item').length;
  //   // console.log(countPositions);
  //   let viewportWidth = window.innerWidth;
  //   let carousel = document.getElementById("course-carousel");

  //   if (countPositions > 10) {
  //     maxCarouselPositions = 3;
  //   }


  //   anchors2.style.opacity = "0.5";
  //   anchors2.style.pointerEvents = "none";

  //   anchors.onclick = function() {
  //     console.log(carouselPosition);
      
  //     var elems = document.querySelectorAll('.course-carousel-item');
  //     // for(var i=0;i<elems.length;i++){
  //     //   elems[i].style.width = "" + viewportWidth / 5 + "px";   
  //     // }
      
  //     if (carouselPosition == 1) {
  //       carousel.style.marginLeft = "-" + viewportWidth + "px"; 
  //     }
  //     if (carouselPosition == 2) {
  //       carousel.style.marginLeft = "-" + viewportWidth * carouselPosition + "px";
  //     }
      
  //     if (carouselPosition > 2) {
  //       return
  //     } else {

  //       ++carouselPosition;
  //       if (carouselPosition == maxCarouselPositions) {
  //         anchors.style.opacity = "0.5";
  //         anchors.style.pointerEvents = "none";
  //       } else {
  //         anchors.style.opacity = "1";
  //         anchors.style.pointerEvents = "initial";
  //       }

  //       if (carouselPosition == 1) {
  //         anchors2.style.opacity = "0.5";
  //         anchors2.style.pointerEvents = "none";
  //       } else {
  //         anchors2.style.opacity = "1";
  //         anchors2.style.pointerEvents = "initial";
  //       }
  //     }
  //   };

  //   anchors2.onclick = function() {

  //     let carouselMargin = window.getComputedStyle(carousel).marginLeft;

  //     if (carouselPosition == 1) {
  //       carousel.style.marginLeft = "0";  
  //     } else {
  //       --carouselPosition;
  //       let carouselMove = Math.abs(parseInt(carouselMargin)) - Number(viewportWidth);
  //       carousel.style.marginLeft = "-" + carouselMove + "px";

  //       if (carouselPosition == maxCarouselPositions) {
  //         anchors.style.opacity = "0.5";
  //         anchors.style.pointerEvents = "none";
  //       } else {
  //         anchors.style.opacity = "1";
  //         anchors.style.pointerEvents = "initial";
  //       }

  //       if (carouselPosition == 1) {
  //         anchors2.style.opacity = "0.5";
  //         anchors2.style.pointerEvents = "none";
  //       } else {
  //         anchors2.style.opacity = "1";
  //         anchors2.style.pointerEvents = "initial";
  //       }
  //     } 
  //   };
  // }

  $("#go-to-card-data").on("click", function () {
    let pwdField = $("#password").val();
    let confirmPwdField = $("#password_confirmation").val();
    if (pwdField == "" || confirmPwdField == "") {
      $("#alert-field-msg").fadeIn();
      $("#alert-field-msg").html("Preencha todos os campos.");
    } else {
      $("#creditcard-fields").fadeIn();
      $("#creditcard-fields").css({
        "position": "absolute",
        "top": "0",
        "background-color": "#000",
      });
    }
    
  });

  $("#go-to-pwd-data").on("click", function () {
    $("#creditcard-fields").fadeOut();
  });

  if (document.getElementById('btn-star')) {
    var starBtn = document.getElementById('btn-star');
    starBtn.onclick = function() {
      $('.btn-favorite').toggleClass('favorited-video');
    }
  }

  if (document.getElementById('btn-like')) {
    var likeBtn = document.getElementById('btn-like');
    likeBtn.onclick = function() {
      $('.btn-like').toggleClass('favorited-video');
    }
  }

  if (document.getElementById('btn-add-comment')) {
    var showCommentBtn = document.getElementById('btn-add-comment');
    showCommentBtn.onclick = function() {
      $('.add-comment').show();
    }
  }
  
  doc.addEventListener('turbo:visit', () => {
    firstLoad = false

    // Clean up layoutHelpers
    if (layoutHelpers) {
      layoutHelpers.destroy()
    }

    // Clean up material ripple
    if (detachMaterialRipple) {
      detachMaterialRipple()
    }

    // Destroy sidenav
    if (sidenavInstance) {
      sidenavInstance.destroy()
    }

    // Remove sidenav toggler listeners
    $('body').off('click', '.layout-sidenav-toggle')
  })
})(window, document, jQuery)
